"use client";

import { Link } from "src/navigation";
import gtmEventHandler from "lib/gtmEventHandler";
export const GTMLink = ({
  event = "select_content",
  href,
  Content_Type,
  Content_Name,
  Content_Block,
  children,
  ...restProps
}: Props) => {
  const handleClick = () => {
    gtmEventHandler.pushToDataLayer({
      event: event,
      Content_Type: Content_Type,
      Content_Name: Content_Name,
      Content_Block: Content_Block
    });
  };
  if (Content_Type && Content_Name && Content_Block) {
    return <Link href={href} onClick={handleClick} {...restProps}>
			{children}
		</Link>;
  } else {
    return <Link href={href} {...restProps}>
			{children} 
		</Link>;
  }
};
interface Props {
  event?: string;
  href: string;
  Content_Type: string;
  Content_Name: string;
  Content_Block: string;
  children: any;
  [restProps: string]: unknown;
}
export default GTMLink;