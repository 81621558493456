"use client";

import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import styles from "./video-banner.module.scss";
import SanityImage from "@sanity-image";
import SanitySource from 'components/helper/sanity-source';
import MarkdownParser from "components/rich-text/markdown-parser";
import Link from "next/link";
const VideoWrapper = props => {
  return <iframe role="presentation" className={styles.vimeoContainer} src={`https://player.vimeo.com/video/${props.vimeoID}?speed=0&pip=0&loop=1&background=1&app_id=122963`} height="100%" width="100%" data-sentry-component="VideoWrapper" data-sentry-source-file="index.jsx" />;
};
VideoWrapper.propTypes = {
  vimeoID: PropTypes.string.isRequired
};
const ImageWrapper = ({
  mobilePortrait,
  landscapeFallBack
}) => {
  return <div className={styles.image} data-sentry-component="ImageWrapper" data-sentry-source-file="index.jsx">
			<picture className={styles.picture} data-sentry-element="picture" data-sentry-source-file="index.jsx">
				{mobilePortrait && <SanitySource media="(max-width: 700px)" image={mobilePortrait} />}
				{landscapeFallBack && <SanitySource media="(min-width: 700px)" image={landscapeFallBack} />}
				<SanityImage fill={true} sizes={"100vw"} priority={true} image={mobilePortrait} data-sentry-element="SanityImage" data-sentry-source-file="index.jsx" />
			</picture>
		</div>;
};
ImageWrapper.propTypes = {
  landscapeFallBack: PropTypes.shape(SanityImage.propTypes).isRequired,
  mobilePortrait: PropTypes.shape(SanityImage.propTypes).isRequired
};
const VideoBanner = ({
  videoBanner,
  disableVideo
}) => {
  const [windowWidth, setWindowWidth] = useState(null);
  const {
    vimeoID,
    title,
    cta,
    link,
    mobilePortrait,
    landscapeFallBack
  } = videoBanner;
  useEffect(() => {
    setWindowWidth(window?.innerWidth);
  }, []);
  return <section className={`${styles.videoBanner} ${styles["component"]}`} data-sentry-component="VideoBanner" data-sentry-source-file="index.jsx">
			{!disableVideo && windowWidth > 600 && <VideoWrapper vimeoID={vimeoID} />}
			<ImageWrapper mobilePortrait={mobilePortrait} landscapeFallBack={landscapeFallBack} data-sentry-element="ImageWrapper" data-sentry-source-file="index.jsx" />

			<div className={styles.copy}>
				<h1 className={styles.title}>
					<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.jsx">{title}</MarkdownParser>
				</h1>
				<Link href={link} className={`button secondary ${styles.button}`} data-sentry-element="Link" data-sentry-source-file="index.jsx">
					{cta}
				</Link>
			</div>
		</section>;
};
VideoBanner.propTypes = {
  disableVideo: PropTypes.bool,
  videoBanner: PropTypes.shape({
    cta: PropTypes.string,
    landscapeFallBack: PropTypes.object,
    link: PropTypes.string,
    mobilePortrait: PropTypes.object,
    title: PropTypes.string,
    vimeoID: PropTypes.string.isRequired
  })
};
export default VideoBanner;