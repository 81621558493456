import PropTypes from "prop-types";
import { GTMLink } from "components/controls/links/GTMLink";
import styles from "./redundant-link.module.scss";
//For redundant links we want to remove from the tab order.
const RedundantLink = ({
  href,
  ...restProps
}) => {
  if (!href) {
    return null;
  }
  return <GTMLink className={styles.redundantlink} aria-hidden="true" tabIndex="-1" href={href} {...restProps} data-sentry-element="GTMLink" data-sentry-component="RedundantLink" data-sentry-source-file="index.jsx" />;
};
RedundantLink.propTypes = {
  href: PropTypes.string
};
export default RedundantLink;