import PropTypes from "prop-types";
import { sanityClient } from 'lib/sanity.server';
import imageUrlBuilder from '@sanity/image-url';

//TODO use the ones set in Next config.
const defaultSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
const getSrc = (source, width = "400") => {
  if (source) {
    return imageUrlBuilder(sanityClient({}))?.image(source)?.width(width)?.quality(75)?.auto("format")?.url();
  } else {
    return null;
  }
};
const SanitySource = ({
  image,
  sizes = "100vw",
  media
}) => {
  const srcSet = defaultSizes.map(size => {
    const src = getSrc(image, size);
    return `${src} ${size}w`;
  }).join(", ");
  return <source srcSet={srcSet} sizes={sizes} media={media} data-sentry-component="SanitySource" data-sentry-source-file="sanity-source.jsx" />;
};
SanitySource.propTypes = {
  image: PropTypes.object,
  media: PropTypes.string,
  sizes: PropTypes.string
};
export default SanitySource;