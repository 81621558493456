import { ReactNode } from "react";
import styles from "./badge.module.scss";
const Badge = ({
  text,
  color = "currentColor",
  icon
}: Props) => {
  return <div className={styles.badge} style={{
    color: color
  }} data-sentry-component="Badge" data-sentry-source-file="index.tsx">
			{icon || null}
			{text}
		</div>;
};
interface Props {
  text: string;
  icon?: ReactNode;
  color?: string;
}
export default Badge;